<template>
  <v-container class="pa-0 ma-0">
    <v-img
      class="hero"
      height="100vh"
      width="100vw"
      src="https://images.pexels.com/photos/6271677/pexels-photo-6271677.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
    >
      <v-overlay absolute opacity=".4">
        <v-card elevation="12" class="pa-14" color="grey darken-2">
          <h2 class="display-2">OVERLANDER CLASSIFIEDS</h2>
          <h2 class="display-2">Coming Soon</h2>
          <p class="title text-center py-6">
            Search for rigs by make, model, modifications and more...
          </p>
          <div class="d-flex justify-space-between mx-8">
            <a target="_blank" :href="item.url" v-for="item in socials" :key="item.i">
              <v-icon color="amber" size="50" @click="socials(item.url)">
                {{ item.icon }}
              </v-icon>
            </a>
          </div>
        </v-card>
      </v-overlay>
    </v-img>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/overlander_classifieds/",
      },
      {
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/company/overlander-classifieds/about/?viewAsMember=true",
      },
      { icon: "mdi-discord", url: "https://discord.gg/GDptd4Gd" },
      { icon: "mdi-twitter", url: "https://twitter.com/Overlanderfieds" },
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/Overlander-Classifieds-105585265278272",
      },
    ],
  }),
};
</script>

<style scoped>
.hero {
  max-height: 100vh !important;
  max-width: 100vw !important;
  position: relative;
}

a {
  text-decoration: none;
}
</style>
